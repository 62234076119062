/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { propertyJSONMeta as r } from "./property.js";
function o(o, e, t) {
  let a, c;
  return void 0 === e || Array.isArray(e) ? (c = o, t = e, a = [void 0]) : (c = e, a = Array.isArray(o) ? o : [o]), (o, e) => {
    const d = o.constructor.prototype;
    a.forEach(a => {
      const s = r(o, a, c);
      s.read && "object" == typeof s.read || (s.read = {}), s.read.reader = d[e], t && (s.read.source = (s.read.source || []).concat(t));
    });
  };
}
export { o as reader };